export default {
    FORM_CHANGED_CURRENCY: 'app.exchange_form.currency.changed',
    FORM_CHANGED_SERVICES: 'app.exchange_form.services.changed',
    FORM_CRYPTO_RATES_UPDATED: 'app.exchange_from.crypto_rates.changed',
    FORM_AMOUNT_UPDATED: 'app.exchange_from.amount.changed',
    FORM_AMOUNT_CALCULATED: 'app.exchange_from.amount.calculated',
    FORM_DROP_DOWN_EMPTY: 'app.exchange_form.drop-down.empty',
    FORM_DROP_DOWN_OPEN: 'app.exchange_form.drop-down.open',
    FORM_DROP_DOWN_CLOSE: 'app.exchange_form.drop-down.close',
    FORM_DROP_DOWN_DATA_LOADED: 'app.exchange_form.drop-down.data-loaded',
}
